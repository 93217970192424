import { PageProps } from 'gatsby';
import { BannerData } from 'packages/innedit';
import React, { FC } from 'react';

import Item from '~/components/List/Item/Banner';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/banner.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';

const BannersPage: FC<PageProps & EspaceProps & UserProps> = ({
  location,
  espace,
  user,
  params: { channelId, espaceId },
}) => {
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          model={
            new BannerData({
              espaceId,
              params,
              wheres: {
                [`channels.${channelId}.isVisible`]: true,
              },
            })
          }
          search={location.search}
          subMenu={items}
          title={channel?.label}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(BannersPage);
